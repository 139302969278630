/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Icon from "../GlobalComponents/Icon";
import GymTitle from "./GymTitle";
import GymSubtitle from "./GymSubtitle";
import GymProgramCard from "./GymProgramCard";
import Container from "../GlobalComponents/Container";
import image2 from "../Image/image2.jpeg";
import image1 from "../Image/image1.jpeg";
import image3 from "../Image/image3.jpeg";
import image10 from "../Image/image10.jpeg";
import image5 from "../Image/image5.jpeg";
import image6 from "../Image/image6.jpeg";
import MASTER_LOGO from "../../Image/MASTER_LOGO.png";

const GymProgram = () => (
    <div css={styles} className="gymProgram">
        <GymTitle />

        <div
            style={{
                borderBottom: "2px solid #0081f8",
                width: "20%",
                margin: "auto",
            }}
        />
        <GymSubtitle />
        <Container>
            {/* <GymProgramCard
        title="Basic Fitness"
        desc="Please do not re-distribute this template ZIP file on any template collection website. This is not allowed."
        image1={image1}
      />
      
      <GymProgramCard
        title="Advanced Muscle Course"
        desc="You may want to browse through Digital Marketing or Corporate HTML CSS templates on our website."
        image1={image2}
      />
      <GymProgramCard
        title="New Gym Training"
        desc="If you wish to support TemplateMo website via PayPal, please feel free to contact us. We appreciate it a lot."
        image1={image6}
      />
      <GymProgramCard
        title="Yoga Training"
        desc="This template is built on Bootstrap v4.3.1 framework. It is easy to adapt the columns and sections."
        image1={image3}
      />
      <GymProgramCard
        title="Basic Muscle Course"
        desc="Credit goes to Pexels website for images and video background used in this HTML template."
        image1={image4}
      />
      <GymProgramCard
        title="Body Building Course"
        desc="Suspendisse fringilla et nisi et mattis. Curabitur sed finibus nisi. Integer nibh sapien, vehicula et auctor."
        image1={image5}
      /> */}
            {/* <div class="row" style={{ width: "100%", height: "",marginBottom:"25px" }}>
        <div class="col-lg-6 col-sm-12" style={{}}>
          <img src={image1} alt="image1" style={{ height: "700px" }} />

        </div>
        <div class="col-lg-6 col-sm-12" style={{ backgroundColor: "" }}>
          <div class="row" style={{ height: "50%" }}>
            <img src={image2} alt="image2" style={{ height: "100%" }} />

          </div>
          <div class="row" style={{ height: "50%" }}>
            <img src={image3} alt="image3" style={{}} />

          </div>
        </div>
      </div> */}
            <div className="row" style={{ width: "100%", height: "" }}>
                {/* <div className="col-lg-6 col-sm-12" style={{width:""}}>
          <img src={image5} alt="image6" style={{ height: "700px",width:"", }} />
        </div> */}
                {/* <div className="col" style={{ backgroundColor: "" }}> */}
                {/* <div class="row" style={{ height: "50%" }}>
            <img src={image10} alt="image10" style={{ height: "100%" }} />

          </div>
          <div class="row" style={{ height: "50%" }}>
            <img src={image6} alt="image6" style={{}} />

          </div> */}
                <div className="col-12" style={{ width: "" }}>
                    <img
                        src={MASTER_LOGO}
                        alt="image10"
                        style={{ height: "480px" }}
                    />
                </div>
                {/* </div> */}
            </div>
        </Container>
    </div>
);

const styles = css`
    width: 100%;
    min-height: 100vh;
    text-align: center;
    padding: 120px 0;
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 40px 0;
    }
    @media (max-width: 700px) {
        .container {
            flex-direction: column;
            justify-content: flex-start;
        }
    }
    @media (min-width: 701px) and (max-width: 1100px) {
        .container {
            flex-direction: column;
            align-items: center;
        }
    }
`;

export default GymProgram;
