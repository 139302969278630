import React from "react";
import Main from "../Main/Main";
import GymProgram from "../GymProgram/GymProgram";
import Member from "../Member/Member";
import Classes from "../OurClasses/Classes";
import Schedule from "../Schedule/Schedule";
import Trainers from "../Trainers/Trainers";
import Contact from "../Contact/Contact";
import Newsletter from "../newsletter/newsletter";
import About from "../about/About";


const Home = () => (
    <>
        <Main />
        <Classes />
        <About />
        <Member />
       
        <Newsletter />
        <Schedule />
        <Trainers />
        <Contact />
    </>
);

export default Home;
