import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MASTER_LOGO from "../../Image/MASTER_LOGO.png";
import { useNavigate } from 'react-router-dom';


const SetPassword = () => {
  const { token } = useParams();
  const letsGo = useNavigate();
  console.log(token);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handlePasswordConfirmation = async () => {
    try {
      if (newPassword !== confirmPassword) {
        setMessage('Passwords do not match.');
        return;
      }

      
      const response = await axios.put(`http://localhost:5000/api/users/reset`, {
        passwordToken: token,
        password: newPassword,
      }
        // { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
      );

      if (response.status === 200) {
      
        toast.success('Password confirmed successfully.');
        letsGo("/login");
      } 
    } catch (error) {
      console.error('Error confirming password:', error);
      toast.error('Password confirmation failed. Please try again.');
    }
  };

return (
    <div
    className="wrapper background text-center"
    style={{ marginTop: "1em" }}
  >
    <div
      className="Heading"
      style={{ paddingTop: "60px", paddingBottom: "20px" }}
    >
      <img
        src={MASTER_LOGO}
        alt="logo"
        className="img-fluid"
        width={300}
        height={200}
      />
    </div>
    <div
      className="pt-5 pb-5 pl-3 pr-3  login-temp"
      style={{
        boxShadow: "0px 0px 30px 0px rgba(30, 23, 16, 0.2)",
        margin: "auto",
        width: "37%",
      }}
    >
      <div className="text-center mb-3">
        <h5 className="sub-head">ForgotPassword</h5>
      </div>
        <div className="input-group mt-3">
          <input
            type="password"
            className="form-control"
            placeholder="Enter your new password"
            style={{ marginLeft: "1em", marginRight: "1em" }}
            value={newPassword}
            onChange={handleNewPasswordChange}
            required
          />
        </div>
        <div className="input-group mt-3">
          <input
            type="password"
            className="form-control"
            style={{ marginLeft: "1em", marginRight: "1em" }}
            placeholder="Confirm your new password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
          />
        </div>
        <br />
        <div>
          <button className="btn btn-primary sign" onClick={handlePasswordConfirmation}>
            Confirm Password
          </button>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
