import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/authContext.js";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const SingleEventPage = () => {
  const { eventId } = useParams();
  const location = useLocation();

  const { isAuthenticated, user } = useAuth();
  const letsGo = useNavigate();

  const [eventDetails, setEventDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const moveToRegister = (eventId) => {
    if (!isAuthenticated()) {
      toast.error("Please Login to Register for Events");
      return;
    }
    letsGo("/register/" + eventId);
  };

  const makeApiCall = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/events/get-event-by-id`,
        {
          id: eventId,
        }
      );
      // Handle the response data here
      console.log(response.data);
      setEventDetails(response.data);
    } catch (error) {
      // Handle the error here
      console.error(error);
    } finally {
      // Set loading to false after the API call completes
      setLoading(false);
    }
  };

  useEffect(() => {
    makeApiCall();
  }, [eventId]);

  return (
    <div className="container">
      <div className="eventinfoform">
        {loading ? (
          // Display a loader while loading
          <div>Loading...</div>
        ) : (
          <>
            {/* Your content here */}
            <div style={{ textAlign: "center" }}>
              <div className="eventimage">
                <img
                  src={`${process.env.REACT_APP_BACKEND_API_URL}/image/${eventDetails?.specificEvent?.file}`}
                  alt="Event"
                />
              </div>
              <div style={{ textAlign: "center" }}>
                {isAuthenticated() ? (
                  <button
                    onClick={() => moveToRegister(eventId)}
                    type="submit"
                    className="register-btn-event"
                  >
                    Register
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      sessionStorage.setItem(
                        "previousLocation",
                        location.pathname
                      );
                      letsGo("/login");
                    }}
                    type="submit"
                    className="register-btn-event"
                  >
                    Login to Register
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SingleEventPage;
