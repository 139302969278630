/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import {Link} from "react-router-dom"
import MASTER_LOGO from "../../Image/MASTER_LOGO.png";

const Logo = () => (
    <Link to="/" style={{textDecoration:"none"}}>
    <h2 css={styles}>
        <i>
            VECTO<span style={{ color: "#0081f8" }}>G</span>
        </i>
        {/* <img src={MASTER_LOGO} alt="image10" style={{ height: "10px", }} /> */}
    </h2>
    </Link>
);

const styles = css`
    color: #fff;
    font-size: 30px;
    font-weight: 900;
    line-height: 1;
    cursor: pointer;
    span {
        color: #ed563b;
    }
`;

export default Logo;
