/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Home_1 from "../../Image/Home_1.jpg";
import Home_2 from "../../Image/Home_2.jpg";
import Home_3 from "../../Image/Home_3.jpg";
import Home_4 from "../../Image/Home_4.jpg";
import Home_5 from "../../Image/Home_5.jpg";
import Home_6 from "../../Image/6.jpg";
import Home_7 from "../../Image/10.jpg";

// Styles for desktop grid view
const desktopGridStyle = css`
  display: none;
  @media (min-width: 768px) {
    display: block;
    padding-top: 78px;
    .row {
      display: flex;
    }

    .column {
      flex: 25%;
      padding: 10px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
`;

// Styles for mobile carousel view with equal width and height
const mobileCarouselStyle = css`
@media (max-width: 580px) {
    .css-14noqsg-Main {
        height: 59vh;
    }
}
    
  display: block;
  @media (min-width: 768px) {
    display: none;
  }

  .carousel.carousel-slider {
    padding-top: 78px;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }


  .carousel .slide img {
    width: 100%;
    height: 300px; /* Set a fixed height */
    object-fit: cover; /* Ensures the image covers the container */
  }

  .legend {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
`;

const HomeImage = () => {
  return (
    <div>
      {/* Carousel for mobile view */}
      <div css={mobileCarouselStyle} >
        <Carousel>
          <div>
            <img src={Home_1} alt="Home Image 1" />
          </div>
          <div>
            <img src={Home_2} alt="Home Image 2" />
          </div>
          <div>
            <img src={Home_3} alt="Home Image 3" />
          </div>
          <div>
            <img src={Home_4} alt="Home Image 4" />
          </div>
          <div>
            <img src={Home_5} alt="Home Image 5" />
          </div>
          <div>
            <img src={Home_6} alt="Home Image 6" />
          </div>
          <div>
            <img src={Home_7} alt="Home Image 7" />
          </div>
        </Carousel>
      </div>

      {/* Grid layout for desktop view */}
      <div className="reg2" css={desktopGridStyle}>
        <div className="row">
          <div className="column">
            <img src={Home_1} alt="Home Image 1" />
            <img src={Home_2} alt="Home Image 2" />
          </div>
          <div className="column">
            <img src={Home_7} alt="Home Image 7" />
          </div>
          <div className="column">
            <img src={Home_5} alt="Home Image 5" />
            <img src={Home_4} alt="Home Image 4" />
          </div>
          <div className="column">
            <img src={Home_3} alt="Home Image 3" />
            <img src={Home_6} alt="Home Image 6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeImage;
