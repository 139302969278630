import React from "react";
import { jsx, css } from "@emotion/core";
export default function About() {
    return (
        <div css={styles} className="gymProgram" id="about">
            <h2 css={styles} align="center" style={{ marginTop: "20px" }}>
                ABOUT
            </h2>
            <div
                style={{
                    borderBottom: "2px solid #0081f8",
                    width: "20%",
                    margin: "auto",
                }}
            />
            <div
                style={{
                    width: "80%",
                    margin: "auto",
                    marginBottom: "30px",
                }}
            >
                <h5 align="center" style={{ marginTop: "25px" }}>
                    {" "}
                    BACKGROUND AND MISSION
                </h5>
                <p>
                    VectoG Performance aims to provide services in the field of
                    sports and exercise. The wider goal of the organization is
                    to create a professional environment for athletes that will
                    aim to focus on the following 5 departments.
                    <ul>
                        <li>Sports and exercise research</li>
                        <li>Coaching Education</li>
                        <li>Athlete recruitment and foreign education</li>
                        <li>Infrastructure development</li>
                        <li>Sports and exercise training</li>
                    </ul>
                </p>
                <h5 align="center" style={{ marginTop: "15px" }}>
                    {" "}
                    VISION
                </h5>
                An athlete aims to achieve success by performing at national and
                international level. To achieve this dream, the athlete at the
                least requires sports science, coaching, and infrastructure. One
                or rather multiple aspects of these are either limited to access
                or non-existent in India. But as sports is being seen as a
                career by more and more people, the development is taking place,
                only at a very slow pace. The Indian population has the
                potential, what they need is a professional environment.
                <br />
                <br />
                <h6 align="center">
                    “It is our vision at VectoG that we provide all the missing
                    pieces and create an environment that will help every
                    athlete reach his or her full potential.”
                </h6>
                <br />
                To turn our vision into reality, we created five major
                departments. The creation of this departments originated from
                the necessity to achieve excellence at the highest level in
                sports.
            </div>
        </div>
    );
}
const styles = css`
    width: 100%;
    min-height: 100vh;
    text-align: center;
    padding: 120px 0;
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 40px 0;
    }
    @media (max-width: 700px) {
        .container {
            flex-direction: column;
            justify-content: flex-start;
        }
    }
    @media (min-width: 701px) and (max-width: 1100px) {
        .container {
            flex-direction: column;
            align-items: center;
        }
    }
`;
