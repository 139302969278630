/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Container from "../GlobalComponents/Container";
import Map from "./Map";
import Form from "./Form";

const Contact = () => (
  <section css={styles} className="contact" id="contact">
    <Container>
      <Map />
      <Form />
    </Container>
    <footer>
      <i class="fab fa-facebook-f" style={{ marginRight: "10px" }}></i>
      <i class="fab fa-linkedin-in" style={{ marginRight: "10px" }}></i>
      <a href="https://instagram.com/vectog?utm_medium=copy_link">
        <i class="fab fa-instagram" style={{ marginRight: "10px" }}></i>
      </a>
      <a href="https://twitter.com/vecto_g?s=21">
        <i class="fab fa-twitter" style={{ marginRight: "10px" }}></i>
      </a>

      <p>
        Copyright &copy; 2022 VectoG |{" "}
        <a
          href="https://app.termly.io/document/terms-of-use-for-saas/d3fa7e4f-9a1a-4d34-ba31-732295cf0c5f"
          target="_blank"
        >
          Terms and Conditions.
        </a>{" "}
        |{" "}
        <a
          href="https://app.termly.io/document/privacy-policy/c3791d97-d124-4170-bc5c-2205d0525405"
          target="_blank"
        >
          Privacy Policy.
        </a>
      </p>
    </footer>
  </section>
);

const styles = css`
  width: 100%;
  .container {
    max-width: 100%;
    display: flex;
  }
  footer {
    text-align: center;
    padding: 36px 0;
    p {
      font-size: 14px;
      color: #232d39;
      a {
        text-decoration: none;
        color: #0081f8;
        font-weight: 500;
        &:hover {
          color: #0081f8;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .container {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
`;

export default Contact;
