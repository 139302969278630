import axios from "axios";
import React, { useState } from "react";

const Payment = () => {
  const [amount, setamount] = useState("");
  const [currency, setcurrency] = useState("INR");

  // axios.post;

  return (
    <div>
      <label htmlFor="">Enter Amount</label>
      <br />
      <input type="number" />
      <br />
      <button onClick={""}>Pay Amount</button>
    </div>
  );
};

export default Payment;
