/** @jsx jsx */
import { jsx, css } from "@emotion/core";
// import { Link } from "react-router-dom";
import React from "react";
import { Link } from "react-router-dom";

const Socials = () => (
  <div css={styles} className="socials">
    {/* <i className="fab fa-facebook-f"></i> */}
    <Link to="/abcd" > <i className="fab fa-facebook-f"></i> </Link>
    <Link to="/abcd" > <i className="fab fa-twitter"></i> </Link>
    <Link to="/abcd" > <i className="fab fa-linkedin-in"></i> </Link>
    <Link to="/abcd" > <i className="fab fa-behance"></i> </Link>
    {/* <i className="fab fa-twitter"></i>
    <i className="fab fa-linkedin-in"></i>
    <i className="fab fa-behance"></i> */}
  </div>
);

const styles = css`
  display: flex;
  align-items: center;
  i {
    margin-right: 18px;
    transition: color 600ms ease-in-out;
    cursor: pointer;
    &:hover {
      color: #ed563b;
    }
  }
`;

export default Socials;
