import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./Admin.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuth } from "../../context/authContext.js";
import { useNavigate } from "react-router-dom";

function AllUserList() {
  const [userDetails, setUserDetails] = useState([]);
  const letsGo = useNavigate();

  const isAdmin = Cookies.get("isAdmin") === "true";

  useEffect(() => {
    if (!isAdmin) {
      letsGo("/login");
    }
  }, [isAdmin, letsGo]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get
          (`${process.env.REACT_APP_BACKEND_API_URL}/api/users/get-all-users`,
          { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
        );
        if (response?.data?.users) {
          setUserDetails(response?.data?.users);
          console.log(response?.data?.users);
        } else {
          console.error(
            "Users not found in the API response:",
            response.data.users
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  console.log(userDetails);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="container">
      <div className="event_div">
        <div className="event_btn_">
          <h2>Users</h2>
        </div>
        <Table striped bordered hover size="sm" className="my_event_table">
          <thead>
            <tr>
              <th>S.NO.</th>
              <th>UserId</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone No.</th>
              <th>Gender</th>
              <th>Date Of Birth</th>
              <th>Organization</th>
              <th>Address</th>
           
            </tr>
          </thead>
          <tbody>
            {userDetails?.map((user, index) => (
              <tr key={user?._id}>
                {" "}
             
                <td>{index + 1}</td>
                <td> {user?._id}</td>
                <td>
                  {user?.firstName} {user?.lastName}{" "}
                </td>
                <td>{user?.email}</td>
                <td>{user?.phoneNo}</td>
                <td>{user?.gender}</td>
                <td>
                  {user && user.dateOfBirth ? formatDate(user.dateOfBirth) : ""}
                </td>
                <td>{user?.organization}</td>
                <td>
                  {user?.address
                    ? user.address + (user?.pinCode ? "," + user.pinCode : "")
                    : ""}
                </td>
              
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default AllUserList;
