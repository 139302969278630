/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState } from "react";
import Icon from "../GlobalComponents/Icon";
import LinksContainer from "./LinksContainer";
import Results from "./Results";
import { Link } from "react-router-dom";
import Container from "../GlobalComponents/Container";

const Classes = ({ text }) => {
    const [training, setTraining] = useState("FirstClass");

    return (
        <section css={styles} className="ourClasses" id="ourClasses">
           <Link style={{textDecoration:"none"}} to="/eventDetails/656992bd08d77e2157c792f4">
            <div style={{backgroundColor:"#d9ebf0",paddingBottom:"0px",color:"black"}}>
            <h1 style={{color:"black"}}>
                VectoGames Tennis 2023 (15-17 December)
            </h1>
            </div>
            </Link>
            <h2 style={{paddingTop:"30px"}}>
                Upcoming <span>Events</span>
            </h2>
            <div
                style={{
                    borderBottom: "2px solid #0081f8",
                    width: "20%",
                    margin: "auto",
                }}
            />
<div>
<LinksContainer  />
</div>
            {/* <Container> */}
               
                {/* <Results training={training} /> */}
            {/* </Container> */}
        </section>
    );
};

const styles = css`
    width: 100%;
    padding: 0px 0;
    padding-top: 15px;
    text-align: center;
    padding-bottom: 20px;
    h2 {
        color: #232d39;
        font-size: 26px;
        font-weight: 900;
        line-height: 1;
        span {
            color: #0081f8;
        }
    }
    p {
        color: #7a7a7a;
        font-size: 15px;
        line-height: 1.7;
    }
    .container {
        display: flex;
        justify-content: space-between;
        padding: 80px 0 0 0;
    }
    @media (max-width: 900px) {
        .container {
        
            align-items: center;
        }
    }
    @media (max-width: 580px) {
      padding-top:30px;
        p {
            padding: 0 20px;
            br {
                display: none;
            }
        }
    }
    @media (min-width: 901px) and (max-width: 1226px) {
        .container {
            justify-content: space-between;
            max-width: 40%;
            display:flex
        }
    }
`;

export default Classes;
