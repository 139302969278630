import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./signup.css";
import MASTER_LOGO from "../../Image/MASTER_LOGO.png";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const Signup = ({}) => {
  const letGo = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword,setConfirmPassword]=useState("")
  const [alert, setAlert] = useState("");
  const [error, setError] = useState("");


const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Password and Confirm Password does not match");
      return; 
       }else{
    try {
      const res = await axios
        .post(`${process.env.REACT_APP_BACKEND_API_URL}/api/users/create-user`, {
          firstName,
          lastName,
          email,
          password,
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            // setAlert(res?.data?.message);
            toast.success("Signup Successful");
            setTimeout(() => {
              letGo("/login")
             }, 3000);
          
          }
        });
    } catch (error) {
      // setError(error?.response?.data?.error);
      console.error("Signup failed:", error);
      toast.error("User already exists");
    }
  };
  };


  return (
    <div
      className="wrapper background text-center"
      style={{ marginTop: "1em" }}
    >
      <div
        className="Heading"
        style={{ paddingTop: "60px", paddingBottom: "20px" }}
      >
        <img
          src={MASTER_LOGO}
          alt="logo"
          className="img-fluid"
          width={300}
          height={200}
        />
      </div>
      <form onSubmit={handleSubmit}>
      <div className="login_temp_signup_parent_div">

        <div
          className=" login_temp_signup"
          style={{
            boxShadow: "0px 0px 30px 0px rgba(30, 23, 16, 0.2)",
          }}
        >
          <div className="text-center mb-3">
            <h5 className="sub-head">SIGN UP</h5>
          </div>
          <div style={{ display: "flex" }}>
            <div className="input-group  mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                name="name"
                style={{ marginLeft: "1em", marginRight: "1em" }}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>

            <div className="input-group  mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                name="name"
                style={{ marginLeft: "1em", marginRight: "1em" }}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="input-group  mt-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="email"
              style={{ marginLeft: "1em", marginRight: "1em" }}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div style={{ display: "flex" }}>
            <div className="input-group mb-3 mt-3">
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Password"
                style={{ marginLeft: "1em", marginRight: "1em" }}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="input-group mb-3 mt-3">
              <input
                type="password"
                className="form-control"
                name="confirmPassword"
                placeholder="Confirm Password"
                style={{ marginLeft: "1em", marginRight: "1em" }}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
          </div>
          
          <div className="row">
             <div className="col-12 signinbuttom">
              <button class="btn btn-primary sign-up-buttom" type="submit">
                SUBMIT
              </button>
            </div>
          </div>
          <div className="row mt-2">
            <span>
              Already have an account? &nbsp;<a href="/login">login</a>
            </span>
          </div>
          <br />
          {alert && (
            <Alert style={{ textAlign: "center", padding: "20px " }}>
              {alert}
            </Alert>
          )}
          <br />
          {error && (
            <Alert
              style={{ textAlign: "center", padding: "20px ", color: "red" }}
            >
              {error}
            </Alert>
          )}
         
        </div>
        </div>

      </form>
      <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={5000} />
    </div>
  );
};

export default Signup;
