import React, { useState } from "react";
import "./gallery.css";
import gallery_1 from "../../Image/image1.jpeg";
import gallery_2 from "../../Image/Home_1.jpg";
import gallery_3 from "../../Image/Home_2.jpg";
import gallery_4 from "../../Image/Home_3.jpg";
import gallery_5 from "../../Image/Home_4.jpg";
import gallery_6 from "../../Image/Home_5.jpg";
import gallery_7 from "../../Image/6.jpg";
import gallery_8 from "../../Image/10.jpg";

// import { GrCertificate } from "react-icons/gr";
// import Navbar from "../../components/navbar/Navbar";
// import Footer from "../../components/footer/Footer";
// import polad from "../../img/polad.jpg";
// import rolling from "../../img/rolling.jpeg";
// import steel from "../../img/steel.jpeg";
// import ssrailing from "../../img/ssrailing.jpg";
// import rollingShutters from "../../img/rollingShutters.jpeg";
// import rolling_shutters from "../../img/rolling_shutters.jpeg";
// import kanagi from "../../img/kanagi.jpg";
// import kitchenTrolly from "../../img/kitchenTrolly.jpg";
// import mykCement from "../../img/mykCement.jpg";
// import work2 from "../../img/work2.jpeg";
// import work4 from "../../img/work4.jpg";
import { Modal, Button } from "react-bootstrap";
import Nav from "../Navbar/Nav";

// import steelRailing from "../../img/steelRailing.jpeg";
// function openImg(){
//     var image = document.getElementById('image');
//     var source = image.src;
//     window.open(source);
// }

const Gallery = () => {
    const [show, setShow] = useState(false);
    const [activeUrl, setActiveUrl] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            {/* <Nav /> */}
            <h1
                className="text-center section-title"
                style={{
                    marginBottom: "1em",
                    color: "rgb(0, 118, 255)",
                    textTransform: "uppercase",
                    marginTop: "1em",
                }}
            >
                <br></br>
                Work gallery
            </h1>
            <div class="grid-container mb-5">
                <a
                    onClick={() => {
                        setActiveUrl(gallery_1);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_1}></img><br/>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_2);
                        handleShow();
                    }}
                >
                    <img  className="gallery" style={{width:'100%'}} src={gallery_2}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_3);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_3}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_4);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_4}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_5);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_5}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_6);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_6}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_7);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_7}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_8);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_8}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_3);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_4}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_5);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_5}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_1);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_1}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_7);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_7}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_3);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_3}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_2);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_2}></img>

                </a>
                <a
                    onClick={() => {
                        setActiveUrl(gallery_6);
                        handleShow();
                    }}
                >
                    <img className="gallery" style={{width:'100%'}} src={gallery_6}></img>

                </a>
                 {/*<a
                    onClick={() => {
                        setActiveUrl(rolling);
                        handleShow();
                    }}
                >
                    <img src={rolling}></img>
                    <p>test image</p>
                </a>
                <a
                    onClick={() => {
                        setActiveUrl(polad);
                        handleShow();
                    }}
                >
                    <img src={polad}></img>
                    <p>test image</p>
                </a>
                <a
                    onClick={() => {
                        setActiveUrl(steel);
                        handleShow();
                    }}
                >
                    <img src={steel}></img>
                    <p>test image</p>
                </a>
                <a
                    onClick={() => {
                        setActiveUrl(steelRailing);
                        handleShow();
                    }}
                >
                    <img src={steelRailing}></img>
                    <p>test image</p>
                </a>
                <a
                    onClick={() => {
                        setActiveUrl(ssrailing);
                        handleShow();
                    }}
                >
                    <img src={ssrailing}></img>
                    <p>test image</p>
                </a>

                <a
                    onClick={() => {
                        setActiveUrl(rolling_shutters);
                        handleShow();
                    }}
                >
                    <img src={rolling_shutters}></img>
                    <p>test image</p>
                </a>
                <a
                    onClick={() => {
                        setActiveUrl(work2);
                        handleShow();
                    }}
                >
                    <img src={work2}></img>
                    <p>test image</p>
                </a>
                <a
                    onClick={() => {
                        setActiveUrl(work4);
                        handleShow();
                    }}
                >
                    <img src={work4}></img>
                    <p>test image</p>
                </a>
                <a
                    onClick={() => {
                        setActiveUrl(work2);
                        handleShow();
                    }}
                >
                    <img src={work2}></img>
                    <p>test image</p>
                </a>
                <a
                    onClick={() => {
                        setActiveUrl(work4);
                        handleShow();
                    }}
                >
                    <img src={work4}></img>
                    <p>test image</p>
                </a> */}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <img src={activeUrl} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* <Footer /> */}
        </>
    );
};

export default Gallery;
