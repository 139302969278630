import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/authContext";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import {Link} from "react-router-dom";
import axios from "axios";

import "./register.css";

import { useParams } from "react-router-dom";

const Personal = () => {
  const letsGo = useNavigate();

  const { isAuthenticated } = useAuth();
  const {user} = useAuth();

  const { eventId } = useParams();

  useEffect(() => {
    if (!isAuthenticated()) {
      letsGo("/login");
    }
  }, [isAuthenticated, letsGo]);

  const [selectedOption, setSelectedOption] = useState(null);

  // const [totalCost, setTotalCost] = useState(0);

  const [formData, setFormData] = useState({
    _id: "",

    firstName: "",

    lastName: "",

    email: "",

    gender: "",

    phoneNo: "",

    dateOfBirth: "",

    organization: "",

    fathersName: "",

    guardiansPhoneNo: "",

    address: "",

    pinCode: "",

    tshirtSize: "",

    sign: "",

    liability: false,

    // registrationCost: 0,
  });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // const handleOptionChangeCost = (event) => {
  //   if (event.target.value === "individual-cost") {
  //     setTotalCost(1200);

  //     setSelectedOption(event.target.value);
  //   } else if (event.target.value === "accommodation-cost") {
  //     setTotalCost(3800);

  //     setSelectedOption(event.target.value);
  //   }
  // };

  useEffect(() => {
    setFormData({
      userId: user?._id || "",

      eventId: eventId,

      firstName: user?.firstName || "",

      lastName: user?.lastName || "",

      email: user?.email || "",

      gender: user?.gender || "",

      phoneNo: user?.phoneNo || "",

      dateOfBirth: formatISODate(user?.dateOfBirth) || "",

      organization: user?.organization || "",

      fathersName: user?.fathersName || "",

      guardiansPhoneNo: user?.guardiansPhoneNo || "",

      address: user?.address || "",

      pinCode: user?.pinCode || "",

      tshirtSize: user?.tshirtSize || "",

      sign: user?.firstName || "",

      liability: false,

      // registrationCost: totalCost,
    });
  }, [user]);

  function formatISODate(isoDate) {
    if (!isoDate) return "";

    const dateObject = new Date(isoDate);

    return dateObject.toISOString().split("T")[0];
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const registrationCost = selectedOption === "individual-cost" ? 1200 : 3800;

    try {
      const res = await axios.post
        (`${process.env.REACT_APP_BACKEND_API_URL}/api/registrations/create-registration`,

        {
          ...formData

          // registrationCost: registrationCost,
        }
      );

      if (res?.status === 200) {
        console.log(res);

        toast.success("Registration Successful");

        letsGo("/userRegistered/" + res?.data?.registration?._id);
      } else {
        toast.error("Please fill all the fields");
      }
    } catch (error) {
      toast.error("Registration failed");

      console.error("Error during form submission:", error);
    }
  };

  console.log(formData);

  return (
    <div>
      <div className="register">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div>
           
              <div>
                <div className="form-box">
                  <h2 className="register-headi">Participants Information </h2>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="firstName"
                          placeholder="First Name *"
                          value={formData?.firstName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              firstName: e.target.value,
                            })
                          }
                        />
                      </div>

                      <br />

                      <div className="form-group">
                        <input
                          required
                          type="text"
                          name="lastName"
                          className="form-control"
                          placeholder="Last Name *"
                          value={formData?.lastName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              lastName: e.target.value,
                            })
                          }
                        />
                      </div>

                      <br />

                      <div className="form-group" style={{display:"flex",alignItems:"center"}}>
                      <label for="T-Shirt">DOB:</label>
                        <input
                          required
                          style={{ color: "grey" }}
                          type="date"
                          className="form-control"
                          name="DateOfBirth"
                          placeholder="Date of Birth"
                          value={formData?.dateOfBirth}
                           onChange={(e) =>
                            setFormData({
                              ...formData,

                              dateOfBirth: e.target.value,
                            })
                          }
                        />
                      </div>

                      <br />

                      <div className="form-group">
                        <label for="T-Shirt">T-Shirt:</label>

                        <select
                          className="select-option"
                          required
                          id="t-shirt"
                          name="t-shirt"
                          value={formData?.tshirtSize} // Update with the appropriate state variable
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              tshirtSize: e.target.value,
                            })
                          }
                        >
                          <option value="">Select</option>

                          <option value="Xl">Xl</option>

                          <option value="S">S</option>

                          <option value="M">M</option>

                          <option value="L">L</option>

                          <option value="XL">XL</option>
                        </select>
                      </div>

                      <br />

                      <div className="form-group">
                        <input
                          required
                          type="text"
                          name="fathersName "
                          placeholder="Father's Name *"
                          className="form-control"
                          value={formData?.fathersName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              fathersName: e.target.value,
                            })
                          }
                        />
                      </div>

                      <br />

                      <div className="form-group">
                        <input
                          required
                          type="number"
                          name="guardiansPhoneNo"
                          className="form-control"
                          placeholder="Guardians Phone No. *"
                          value={formData?.guardiansPhoneNo}
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              guardiansPhoneNo: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <br/>
                     <br/>
                    <div className="col-sm-6" >
                      <div className="form-group-email-mobile">
                      <div className="form-group">
                        <input
                          required
                          pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                          type="email"
                          className="form-control "
                          name="email"
                          placeholder="Email *"
                          value={formData?.email}
                          // onChange={(e) =>
                          //   setFormData({ ...formData, email: e.target.value })
                          // }
                        />
                      </div>
                      </div>
                      <br />

                      <div className="form-group">
                        <input
                          required
                          type="number"
                          name="PhoneNo"
                          className="form-control"
                          placeholder="Phone No. *"
                          value={formData?.phoneNo}
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              phoneNo: e.target.value,
                            })
                          }
                        />
                      </div>

                      <br />

                      <div className="form-group">
                        <label for="gender">Gender:</label>

                        <select
                          className="select-option"
                          id="gender"
                          name="gender"
                          value={formData?.gender} // Update with the appropriate state variable
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              gender: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select</option>

                          <option value="male">Male</option>

                          <option value="female">Female</option>
                        </select>
                      </div>

                      <br />

                      <div className="form-group">
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="address"
                          placeholder="Address *"
                          value={formData?.address}
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              address: e.target.value,
                            })
                          }
                        />
                      </div>

                      <br />

                      <div className="form-group">
                        <input
                          required
                          type="number"
                          className="form-control"
                          name=" pinCode"
                          placeholder="Zipcode *"
                          value={formData?.pinCode}
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              pinCode: e.target.value,
                            })
                          }
                        />
                      </div>

                      <br />

                      <div className="form-group">
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="organization"
                          placeholder="Organisation *"
                          value={formData?.organization}
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              organization: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* Event Participation section */}

                  <h2 className="register-headi" style={{ paddingTop: "4%" }}>
                    Event Participation
                  </h2>

                  <div>
                    {/* <div className="row">
                      <div className="registration-option">
                        <input
                        required
                          type="radio"
                          name="registration"
                          value="individual-cost"
                          checked={selectedOption === "individual-cost"}
                          onChange={handleOptionChangeCost}
                        />

                        <label>
                          Registration cost: ₹ 1200 per individual excluding
                          accommodation
                        </label>
                      </div>

                      <div className="registration-option">
                        <input
                          type="radio"
                          name="registration"
                          value="accommodation-cost"
                          checked={selectedOption === "accommodation-cost"}
                          onChange={handleOptionChangeCost}
                        />

                        <label>
                          Registration cost: ₹ 3800 per individual including
                          accommodation and meal
                        </label>
                      </div>
                    </div> */}

                    <hr />

                    <div style={{ fontSize: "12px" }}>
                      <label>
                        <b>
                          Liability Waiver{" "}
                          <span className="liability-color">*</span>
                        </b>
                      </label>

                      <p className="para-participation">
                        <input
                          required
                          className="checkbox"
                          type="checkbox"
                          name="liability"
                          checked={formData?.liability}
                          onChange={(e) =>
                            setFormData({
                              ...formData,

                              liability: e.target.value,
                            })
                          }
                        />
                        <span> &nbsp;</span>
                        I, the undersigned, being aware of my own health and
                        physical condition, and having knowledge that my
                        participation in any event that requires physical
                        activity may be injurious to my health, am voluntarily
                        participating in a physical activity. Having such
                        knowledge, I hereby acknowledge this release, the
                        organizers of VectoG, any representatives, agents, and
                        successors from liability for accidental injury or
                        illness which I may incur as a result of participating
                        in the said event. I hereby assume all risks connected
                        therewith and consent to participate in said event. I
                        agree to disclose any physical limitations,
                        disabilities, ailments, or impairments which may affect
                        my ability to participate in said event that requires
                        physical activity.
                      </p>

                      <label>
                        <b>
                          Sign <span className="liability-color">*</span> :{" "}
                        </b>

                        <input
                          className="sign"
                          type="text"
                          name="sign"
                          placeholder="Enter your Full Name..."
                          value={formData?.sign}
                          onChange={(e) =>
                            setFormData({ ...formData, sign: e.target.value })
                          }
                          required
                        />
                      </label>

                      <br />

                      {/* <label htmlFor="">
                        <b>
                          {" "}
                          Total Amount : INR <span> </span>
                        </b>
                      </label> */}

                      {/* <input
                        className="amount"
                        type="number"
                        value={totalCost}
                        disabled={true}
                      /> */}

                      <br />

                      <button
                        className="btnRegister"
                        type="submit"
                        style={{ width: "25%" }}
                      >
                        Submit
                      </button>

                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default Personal;
