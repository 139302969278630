/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Icon from "../GlobalComponents/Icon";
import TrainerCard from "./TrainerCard";
import Container from "../GlobalComponents/Container";
import TrainerOneBg from "../Image/trainerOne.jpg";
import TrainerTwoBg from "../Image/trainerTwo.jpg";
import TrainerThreeBg from "../Image/trainerThree.jpg";

const Trainers = () => (
  <section css={styles} className="trainers" id="trainers">
    {/* <h2>
      EXPERT <span style={{color:'#0076ff'}}>TRAINER</span>
    </h2>
    <Icon /> */}
    {/* <p>
      Nunc urna sem, laoreet ut metus id, aliquet consequat magna. Sed viverra{" "}
      <br />
      ipsum dolor, ultricies fermentum massa consequat eu.
    </p> */}
    {/* <Container>
      <TrainerCard
        // title="Strength Trainer"
        name="Gouresh Powar"
        desc="Hello friends, my name is Coach Gouresh Powar. I wanted to help the people by providing them with organized training programs that will minimize the injury risks and bad experiences associated with exercise. I welcome you to this program and I hope we will achieve your goals with lot of good memories.
        "
        img={TrainerOneBg}
      />
   
    </Container> */}
  </section>
);

const styles = css`
  width: 100%;
  // padding: 120px 0;
  text-align: center;
  h2 {
    color: #232d39;
    font-weight: 900;
    font-size: 36px;
    letter-spacing: 1.3px;
    line-height: 1;
    span {
      color: #ed563b;
    }
  }
  > p {
    color: #7a7a7a;
    font-size: 16px;
    line-height: 1.7;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0 0 0;
  }
  @media (max-width: 650px) {
    padding-top: 6px;
    > p {
      padding: 0 30px;
      br {
        display: none;
      }
    }
  }
  @media (max-width: 830px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  }
  @media (min-width: 831px) and (max-width: 1226px) {
    .container {
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 780px;
    }
  }
`;

export default Trainers;
