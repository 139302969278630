import React, { useState, useEffect } from "react";

import Table from "react-bootstrap/Table";

import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";

import axios from "axios"; // Import Axios for making API requests

import { useAuth } from "../../context/authContext.js";

import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import './Admin.css'

 

function AllEventDetails() {

  const { allEventDetails } = useAuth();

  const letsGo = useNavigate();

 

  const isAdmin = Cookies.get("isAdmin") === "true";

 

  useEffect(() => {

    if (!isAdmin) {

      letsGo("/login");

    }

  }, [isAdmin, letsGo]);

 

  const handleEditEvent = (eventId) => {

    letsGo(`/admin/editEvent/${eventId}`);

  };

 

  const handleDeleteEvent = (eventId) => {

    if (window.confirm("Are you sure you want to delete this event?")) {

      axios

        .put(`${process.env.REACT_APP_BACKEND_API_URL}/api/events/delete-event`, { _id: eventId },

          {

            headers: {

              "Content-Type": "application/json",

              Authorization: `Bearer ${Cookies.get("accessToken")}`,

            },

          }

        )

        .then((response) => {

          if (response.status === 200) {
            window.location.reload();
            toast.success("Event Deleted Successfully");   
            
           
            console.log(response.data);

            // You should update your event list after deleting the event

          } else {

            console.error("Failed to delete event");

          }

        })

        .catch((error) => {

          console.error("Error deleting event", error);

        });

    }

  };

 

  const formatDate = (dateString) => {

    const options = { year: "numeric", month: "long", day: "numeric" };

    return new Date(dateString).toLocaleDateString(undefined, options);

  };

 

  return (

    <div className="container">

      <div className="event_div">

        <div className="event_btn_div">

          <h2>Events</h2>

          <Link to="/admin/createEvent">

            <Button variant="primary" className="create_event_btn">

              Create Event

            </Button>

          </Link>

        </div>

        <Table striped bordered hover size="sm" className="my_event_table">

          <thead>

            <tr>

              <th>S.NO.</th>

              <th>Event Id</th>

              <th>Category</th>

              <th>Title</th>

              <th>Start Date</th>

              <th>End Date</th>

              <th>Action</th>

            </tr>

          </thead>

          <tbody>

            {allEventDetails?.map((event, index) => (

              <tr key={event?._id}>

                <td>{index + 1}</td>

                <td>{event?._id}</td>

                <td>{event?.category}</td>

                <td>{event?.title}</td>

                <td>{formatDate(event?.startDate)}</td>

                <td>{formatDate(event?.endDate)}</td>

                <td>

                  <Button variant="success" onClick={() => handleEditEvent(event._id)}>

                    Edit

                  </Button>{" "}

                  <Button variant="primary" onClick={() => handleDeleteEvent(event._id)}>

                    Delete

                  </Button>

                </td>

              </tr>

            ))}

          </tbody>

        </Table>

      </div>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>

  );

}

 

export default AllEventDetails;

 