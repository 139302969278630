import React, { useState ,useRef} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MASTER_LOGO from "../../Image/MASTER_LOGO.png";
import "./Admin.css";
import axios from "axios";
import Cookies from "js-cookie";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import { useAuth } from "../../context/authContext.js";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function CreateEvent() {
  const letsGo = useNavigate();

  
  const isAdmin = Cookies.get("isAdmin") === "true";

  useEffect(() => {
    
    if (!isAdmin) {
      letsGo("/login");
    }
  }, [isAdmin, letsGo]);

 const [title , setTitle] = useState("");
 const [category , setCategory] = useState("");
 const [startDate , setStartDate] = useState("");
 const [endDate , setEndDate] = useState("");
 const [file , setFile] = useState(null); // Initialize file as null
 const [alert , setAlert] = useState("");


 const fileInputRef = useRef(null);

  const resetFileInput = () => {
    // Reset the file input by setting its value to an empty string
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

 const handleCategoryChange = (e) => {
    // Update the selected category when the user makes a selection
    setCategory(e.target.value);
 };

 const handleImageChange = (e) => {
    const selectedFile = e.target.files[0]; // Get the first file from the selected files
    setFile(selectedFile); // Set the file in state
 };

 // ...

 const [loading, setLoading] = useState(false); // Add loading state

 const handleSubmit = async (e) => {
   e.preventDefault();

   if (loading) {
     return; // Do nothing if the button is already loading
   }

   setLoading(true); 
    const formData = new FormData();
  
    formData.append("title", title);
    formData.append("category", category);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("file", file); // Use "file" as the key for the image
  
    try {
      const response = await axios.post
        (`${process.env.REACT_APP_BACKEND_API_URL}/api/events/create-event`,
        formData,
        { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
      );
      
      if (response.status === 200) {
        window.location.reload();
          
        // Reset the form values after successful submission
        setTitle("");
        setCategory("");
        setStartDate("");
        setEndDate("");
        resetFileInput();
        toast.success(response?.data?.message);
       

       
      }
    } catch (error) {
      console.log(error);
      toast.error("Event not created", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    finally {
      setLoading(false); // Set loading to false whether the API call succeeds or fails
    }
  };
  
  // ...
  

  return (
    <div className="event_div">
        <div>
        <Link to="/admin/eventlist">
          <button className="btn btn-primary sign">Go Back</button>
        </Link>
      </div>
      <section className="text-gray-600 body-font relative">
        <div className="container pl-5 px-5 py-24 mx-auto">
            <form onSubmit={handleSubmit} >
          <div
            className="pt-5 pb-5 pl-3 pr-3  login-temp"
            style={{
              boxShadow: "0px 0px 30px 0px rgba(30, 23, 16, 0.2)",
              margin: "auto",
              width: "50%",
            }}
          >
            <div className="text-center mb-3">
              <h5 className="sub-head">Create Event</h5>
            </div>

            <div className="input-group  mt-3  mx-2 my-2">
            <div>
              <label className="leading-7 text-sm text-gray-600">Title</label>
              </div>
                
              <input
                type="text"
                className="form-control"
                placeholder="title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{ marginLeft: "1em", marginRight: "1em" }}
                required
              />
            </div>

            <div className="input-group mb-3 mt-3  mx-2 my-2">
            <div>
              <label className="leading-7 text-sm text-gray-600">
                Select Category
              </label>
              </div>
            
              <select
              required
              className="form-control"
                name="category"
                style={{ marginLeft: "1em", marginRight: "1em" }}
                value={category} 
                onChange={handleCategoryChange}
              >
                <option value="" disabled>Select Options</option>
                <option value="VectoGen">VectoGen</option>
                <option value="VectoGames">VectoGames</option>
                <option value="Seminars">Seminars</option>
              </select>
            </div>
            <div className="input-group mb-3 mt-3  mx-2 my-2">
            <div>
              <label className="leading-7 text-sm text-gray-600"> Start Date</label>
              </div>

              <input
               min={new Date().toISOString().slice(0, 10)}
                type="date"
                className="form-control"
                placeholder="StartDate"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                style={{ marginLeft: "1em", marginRight: "1em" }}
                required
              />
            </div>
            <div className="input-group mb-3 mt-3 mx-2 my-2">
                <div>
              <label className="leading-7 text-sm text-gray-600"> End Date</label>
              </div>
            
                
              <input
                type="date"
                min={new Date().toISOString().slice(0, 10)}
                className="form-control"
                placeholder="EndDate" 
                name="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ marginLeft: "1em", marginRight: "1em" }}
                required
                
              />
            </div>
            <div className="input-group mb-3 mt-3 mx-2 my-2">
            <div>
              <label className="leading-7 text-sm text-gray-600">Image</label>
              </div>
            


              <input type="file" className="form-control"
               name="file" id="file" accept="image/"
               style={{ marginLeft: "1em", marginRight: "1em" }}
                onChange={handleImageChange}
                required
                ref={fileInputRef}
               />
            </div>
            <div className="row">
              <div className="col-12 signinbuttom  text-center mb-3">
              <button
              type="submit"
              className="btn btn-primary sign"
              disabled={loading} // Disable the button when loading is true
            >
              {loading ? "Creating..." : "Create"}
            </button>
              </div>
              <br />
              
            </div>
            {/* {alert && (
            <Alert style={{ textAlign: "center", padding: "20px " }}>
              {alert}
            </Alert>
          )} */}
          </div>
            </form>
        </div>
      </section>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
}

export default CreateEvent;
