import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

const AuthContext = createContext();
export function AuthProvider({ children }) {

const [user, setUser] = useState(null);
const [allEventDetails, setAllEventDetails] = useState([]);

  // all-user-profile-details//
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get
          (`${process.env.REACT_APP_BACKEND_API_URL}/api/users/user-profile`,
          { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
        );
        const userFromResponse = response?.data?.user;
        setUser(userFromResponse);
        
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

// all-event-details//
  useEffect(() => {
    const fetchAllEventData = async () => {
      try {
        const response = await axios.get
          (`${process.env.REACT_APP_BACKEND_API_URL}/api/events/get-all-events`,
          { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
        );
        if (response?.data?.events) { 
          setAllEventDetails(response?.data?.events); 
          console.log(response?.data?.events);
          
        } else {
          console.error('Users not found in the API response:', response.data.events);
        }
        
      } catch (error) {
        // console.log(error);
      }
    };
    fetchAllEventData();

  }, []);
  

// registerd-user-data//
  const login = async (userData) => {
    let result = false;
    try {
      const response = await axios.post
        (`${process.env.REACT_APP_BACKEND_API_URL}/api/users/login`,
        {
          email: userData?.email,
          password: userData?.password,
        }
      );

      if (response.status === 200) {
        const userFromResponse = response?.data?.data;
        console.log("User Data from Response:", userFromResponse);
        setUser(userFromResponse);
        toast.success("Login Successful");
        Cookies.set("user", JSON.stringify(userFromResponse));
        Cookies.set("accessToken", response?.data?.token, { expires: 7 });
        Cookies.set("isAdmin",JSON.stringify(userFromResponse?.isAdmin));
        console.log("isAdmin",userFromResponse?.isAdmin)
        result = true;
      }
    } catch (error) {
      toast.error("Invalid UserName or Password");
      console.log(error);
      result = false;
    }
    return result;
  };

  // tooken-user-authentication//
  const isAuthenticated = () => {
    return (
      Cookies.get("accessToken") != undefined &&
      Cookies.get("accessToken") != null &&
      Cookies.get("user") != null &&
      Cookies.get("user") != undefined
    );
  };

  // logout//
  const logout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("user");
    Cookies.remove("isAdmin");
    isAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{allEventDetails,user, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
