import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/authContext.js";

function EditEvent() {
  const letsGo = useNavigate();
  const isAdmin = Cookies.get("isAdmin") === "true";

  useEffect(() => {
    if (!isAdmin) {
      letsGo("/login");
    }
  }, [isAdmin, letsGo]);

  const { eventId } = useParams();
  const { allEventDetails } = useAuth();

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [file, setFile] = useState(null);
  const [oldImage, setOldImage] = useState("");
  const [selectedFileName, setSelectedFileName] = useState(""); // Step 1

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.post
          (`${process.env.REACT_APP_BACKEND_API_URL}/api/events/get-event-by-id`,
          {
            id: eventId,
          },
          { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
        );

        if (response?.data?.specificEvent) {
          const eventData = response.data.specificEvent;
          const formattedStartDate = new Date(eventData.startDate).toISOString().slice(0, 10);
          const formattedEndDate = new Date(eventData.endDate).toISOString().slice(0, 10);
          setTitle(eventData.title);
          setCategory(eventData.category);
          setStartDate(formattedStartDate);
          setEndDate(formattedEndDate);
          setFile(eventData?.file);
          setSelectedFileName(eventData?.file?.name || ''); 

          console.log(eventData.file);
        } else {
          console.error("Event not found in the API response:", response.data.event);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchEventData();
  }, [eventId]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedFileName(selectedFile?.name); 
    setFile(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("_id", eventId);
    formData.append("title", title);
    formData.append("category", category);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("file", file);

    try {
      const response = await axios.put
        (`${process.env.REACT_APP_BACKEND_API_URL}/api/events/update-event`,
        formData,
        { headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` } }
      );

      if (response.status === 200) {
        toast.success("Event updated successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setTitle("");
        setCategory("");
        setStartDate("");
        setEndDate("");
        setFile(null);
        setSelectedFileName(""); // Clear the selected file name
        window.location.reload();
}
    } catch (error) {
      console.log(error);
      toast.error("Event update created", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div className="event_div">
        <div>
        <Link to="/admin/eventlist">
          <button className="btn btn-primary sign">Go Back</button>
        </Link>
      </div>
      <section className="text-gray-600 body-font relative">
        <div className="container pl-5 px-5 py-24 mx-auto">
          <form onSubmit={handleSubmit}>
            <div
              className="pt-5 pb-5 pl-3 pr-3  login-temp"
              style={{
                boxShadow: "0px 0px 30px 0px rgba(30, 23, 16, 0.2)",
                margin: "auto",
                width: "50%",
              }}
            >
              <div className="text-center mb-3">
                <h5 className="sub-head">Edit Event</h5>
              </div>

              <div className="input-group  mt-3  mx-2 my-2">
                <div>
                  <label className="leading-7 text-sm text-gray-600">Title</label>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  style={{ marginLeft: "1em", marginRight: "1em" }}
                  required
                />
              </div>

              <div className="input-group mb-3 mt-3  mx-2 my-2">
                <div>
                  <label className="leading-7 text-sm text-gray-600">
                    Select Category
                  </label>
                </div>
                <select
                  required
                  className="form-control"
                  name="category"
                  style={{ marginLeft: "1em", marginRight: "1em" }}
                  value={category}
                  onChange={handleCategoryChange}
                >
                  <option value="" disabled>
                    Select Options
                  </option>
                  <option value="VectoGen">VectoGen</option>
                  <option value="VectoGames">VectoGames</option>
                  <option value="Seminars">Seminars</option>
                </select>
              </div>

              <div className="input-group mb-3 mt-3  mx-2 my-2">
                <div>
                  <label className="leading-7 text-sm text-gray-600">Start Date</label>
                </div>
                <input
                  type="date"
                  className="form-control"
                  placeholder="StartDate"
                  name="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  style={{ marginLeft: "1em", marginRight: "1em" }}
                  required
                />
              </div>

              <div className="input-group mb-3 mt-3 mx-2 my-2">
                <div>
                  <label className="leading-7 text-sm text-gray-600">End Date</label>
                </div>
                <input
                  type="date"
                  className="form-control"
                  placeholder="EndDate"
                  name="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  style={{ marginLeft: "1em", marginRight: "1em" }}
                  required
                />
              </div>

              <div className="input-group mb-3 mt-3 mx-2 my-2">
                <div>
                  <label className="leading-7 text-sm text-gray-600">Image</label>
                </div>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  id="file"
                  // value={selectedFileName}
                  accept="image/"
                  style={{ marginLeft: "1em", marginRight: "1em" }}
                  onChange={handleImageChange}
                />
                {/* Display the selected file name */}
               
              </div>

              {/* {oldImage && (
                <img
                  src={oldImage}
                  alt="Old Event Image"
                  style={{ maxWidth: "100px" }}
                />
              )} */}

              <div className="row">
                <div className="col-12 signinbuttom  text-center mb-3">
                  <button type="submit" class="btn btn-primary sign">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
}

export default EditEvent;
