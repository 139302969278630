/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import DumbbellBg from "../Image/dumbbellOrange.png";
import Button from "../GlobalComponents/Button";
import { BrowserRouter as useNavigate, Link } from "react-router-dom";

const LinksContainer = ({ setTraining, training }) => (
  <div css={styles} className="linksContainer" >
    <div>
   <Link style={{textDecoration:"none"}} to="/event/VectoGen">
    <button
      style={{ color: "#232D39" }}
      // className={training === "FirstClass" ? "active" : ""}
      // onClick={() => setTraining("FirstClass")}
    >
      {/* <img src={DumbbellBg} alt="dumbbell" /> */}
      VectoGen
    </button>
    </Link >
    </div>
    <div >
    <Link  style={{textDecoration:"none"}} to="/event/VectoGames">
    <button style={{backgroundColor:"rgb(0, 129, 248)",color:"white",borderRadius:"5px"}}
      // className={training === "SecondClass" ? "active" : ""}
      // onClick={() => setTraining("SecondClass")}
    >
      {/* <img src={DumbbellBg} alt="dumbbell" /> */}
      VectoGames
    </button>
    </Link>
    </div>
    <div>
    <Link  style={{textDecoration:"none"}} to="/event/Seminars">
    <button
      // className={training === "ThirdClass" ? "active" : ""}
      // onClick={() => setTraining("ThirdClass")}
    >
      {/* <img src={DumbbellBg} alt="dumbbell" />  */}
      Seminars
    </button>
    </Link>
    </div>
    {/* <button
            className={training === "FourthClass" ? "active" : ""}
            onClick={() => setTraining("FourthClass")}
        >
            <img src={DumbbellBg} alt="dumbbell" /> 
            VectoGames registration
        </button> */}
    {/* <button className={training === "FourthClass" ? "active" : ""}>
      <a href="/event/VectoGen" style={{ textDecoration: "none" }}>
      
        VectoGen registration
      </a>
    </button> */}
  </div>
);

const styles = css`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding-top:50px;

  button {
    width: 100%;
    padding: 28px 36px;
    color: #232d39;
    font-weight: 500;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    border: none;
    outline: none;
    font-size: 20px;
    &.active {
      color: #0081f8;
    }
    img {
      margin-right: 20px;
    }
  }
  .btn {
    padding: 24px 0;
    border-radius: 4px;
  }
  .sch {
    background-color: #edc85a;
    border-radius: 10px;
  }

  @media (max-width: 900px) {
    max-width: 590px;
  }
  @media (min-width: 901px) and (max-width: 1226px) {
    max-width: 280px;
  }
  @media (max-width: 900px) {
    display:block;
  


  }
`;

export default LinksContainer;
