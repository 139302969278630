import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MASTER_LOGO from "../../Image/MASTER_LOGO.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }; 

  const handlePasswordReset = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",

      };
      // Make an API request to your backend to initiate the password reset
      const response = await axios.post
        (`${process.env.REACT_APP_BACKEND_API_URL}/api/users/reset`,
     
        {
          email: email,
        },
        { headers: headers}
      );

      if (response.status === 201 ) {
        toast.success("Password reset email sent successfully.");
      } else {
        setMessage(
          "Password reset request failed. Please check your email and try again."
        );
      }
    } catch (error) {
      console.error("Error sending password reset request:", error);
     toast.error("Email is not Registered");
    }
  };

  return (
    <div
      className="wrapper background text-center"
      style={{ marginTop: "1em" }}
    >
      <div
        className="Heading"
        style={{ paddingTop: "60px", paddingBottom: "20px" }}
      >
        <img
          src={MASTER_LOGO}
          alt="logo"
          className="img-fluid"
          width={300}
          height={200}
        />
      </div>
      <div
        className="pt-5 pb-5 pl-3 pr-3  login-temp"
        style={{
          boxShadow: "0px 0px 30px 0px rgba(30, 23, 16, 0.2)",
          margin: "auto",
          width: "37%",
        }}
      >
        <div className="text-center mb-3">
          <h5 className="sub-head">ForgotPassword</h5>
        </div>

        <div className="input-group  mt-3 ">
          <input
            type="email"
            className="form-control"
            style={{ marginLeft: "1em", marginRight: "1em" }}
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
<br/>
        <div>
          <button class="btn btn-primary sign" onClick={handlePasswordReset}>
            Reset Password
          </button>
        
        </div>
      </div>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default ForgotPassword;
