import axios from "axios";
import React, { useEffect, useState } from "react";
import "./thankYou.css";
import ThumbsUp from "../../Image/thumbsUp.png";

const ThankYou = () => {
 
  return (
    <div>
      <div className="jumbotron text-center">
        <img className="thumbs" src={ThumbsUp} alt="" />
        <h1 className="display-3">Thank You! </h1>
        <h2 className="name-styling">
          {/* {userInfo.firstName} {userInfo.lastName} */}
        </h2>
        {/* <p className="lead">
          Please note your <strong>Order ID and Payment ID</strong> for future
          reference.
        </p> */}
      <h6>You will recieve an  Email after verification</h6>
        {/* <p className="order-id">Order ID : {userInfo?.razorpayOrderId}</p>
        <p className="order-id">Payment ID : {userInfo?.razorpayPaymentId}</p> */}
        <hr />

        <a className="btn btn-primary btn-md" href="/" role="button">
          Continue to Homepage
        </a>
      </div>
    </div>
  );
};

export default ThankYou;
