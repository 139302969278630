import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import MASTER_LOGO from "../../Image/MASTER_LOGO.png";

import { useAuth } from "../../context/authContext.js";

import { useNavigate } from "react-router-dom"; // Import useNavigate

import '../login/login.css'

const Login = () => {
  const { login } = useAuth();

  const letsGo = useNavigate(); // Initialize useNavigate

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userData = { email, password };

    const loginSuccess = await login(userData);

    if (loginSuccess) {
      const previousLocation = sessionStorage.getItem("previousLocation");

      if (previousLocation) {
        letsGo(previousLocation);
        sessionStorage.removeItem("previousLocation");

        window.location.reload();
      } else {
        // If there's no previous location, you can use a default fallback URL.

        letsGo("/"); // Change this to the URL you want to use as a fallback.

        window.location.reload();
      }
    }
  };

  return (
    <div
      className="wrapper background text-center"
      style={{ marginTop: "1em" }}
    >
      <div
        className="Heading"
        style={{ paddingTop: "60px", paddingBottom: "20px" }}
      >
        <img
          src={MASTER_LOGO}
          alt="logo"
          className="img-fluid"
          width={300}
          height={200}
        />
      </div>
      
      <div className="login_temp_parent_div">

      <div
        className="login-temp"
        style={{
          boxShadow: "0px 0px 30px 0px rgba(30, 23, 16, 0.2)",
        }}
      >
        <div className="text-center mb-3">
          <h5 className="sub-head">LOGIN</h5>
        </div>

        <form onSubmit={handleSubmit} action="/" method="post" className="mt-5">
          <div className="input-group  mt-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="email"
              style={{ marginLeft: "1em", marginRight: "1em" }}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="input-group mb-3 mt-3">
            <input
              type="password"
              className="form-control"
              name="password"
              placeholder="Password"
              style={{ marginLeft: "1em", marginRight: "1em" }}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <div className="row">
            <div className="col-12 signinbuttom">
              <button
                class="btn btn-primary sign"
                onClick={(e) => handleSubmit(e)}
              >
                LOGIN
              </button>
            </div>

            <div className="row mt-2">
              <span>
                <a href="/forgotpassword">Forgot Password?</a>
              </span>

              <span>
                Don't have an account yet? &nbsp;<a href="/signup">Sign up.</a>
              </span>
            </div>

            <br />
          </div>
        </form>
      </div>
      </div>


      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default Login;
