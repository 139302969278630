/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Button from "../GlobalComponents/Button";
import { Link } from "react-router-dom";
import gymOverlayBg3 from "../Image/gymOverlayBg3.jpg";
//import 7 from "../Image/7.jpg";

const Member = ({ text }) => (
    <section css={styles} className="member">
        <div>
            <h2
                style={{
                    backgroundColor: "rgba(0,0,0,0.2)",
                }}
            >
                DON’T THINK, BEGIN TODAY!
            </h2>
            <br />
            {/* <Button text="BECOME A MEMBER" /> */}
            {/* <Link to="/Member" className="btn css-12kt7ua-Button" > BECOME A MEMBER </Link> */}
            <Link to="signup" style={{ textDecoration: "none" }}>
                {" "}
                <span
                    style={{
                        color: "white",
                        backgroundColor: "#0081f8",
                        padding: "15px",
                        borderRadius: "5px",
                    }}
                >
                    Sign Up !
                </span>{" "}
            </Link>
        </div>
    </section>
);

const styles = css`
    width: 100%;
    padding: 120px 0;
    text-align: center;
    background: url("${gymOverlayBg3}") no-repeat center/cover;

    h2 {
        color: #fff;
        font-weight: 900;
        font-size: 36px;
        letter-spacing: 1.3px;
        line-height: 1;
        span {
            color: #ed563b;
        }
    }
    p {
        color: #fff;
        font-size: 16px;
        line-height: 1.7;
        margin: 20px 0;
    }
    @media (max-width: 850px) {
        h2 {
            font-size: 30px;
        }
        p {
            padding: 0 20px;
            br {
                display: none;
            }
        }
    }
`;

export default Member;
