import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth } from "../../context/authContext.js";
import "./eventInfo.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useLocation } from "react-router-dom";

const EventDetailsPage = () => {
  const { category } = useParams();
  const { allEventDetails } = useAuth();
  const { isAuthenticated, user } = useAuth();
  const letsGo = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const moveToRegister = (eventId) => {
    letsGo("/eventDetails/" + eventId);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Simulate an API call delay
        // In a real application, replace this with an actual API call
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Filter events based on the category
        const filteredEvents = allEventDetails.filter(
          (event) => event?.category === category
        );

        setFilteredEvents(filteredEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
        toast.error("Error fetching events");
      } finally {
        // Set loading to false after events are fetched
        setLoading(false);
      }
    };

    fetchEvents();
  }, [category, allEventDetails]);

  return (
    <div className="cont">
      <div className="eventinfoform">
        <div className="headingevent">
          <h1>{category}</h1>
        </div>
        {loading ? (
          "Loading..."
        ) : (
          <div className="row">
            {filteredEvents?.map((event) => (
              <div className="col-sm-4" onClick={() => moveToRegister(event._id)}>
                <div class="card" key={event?._id}>
                  <div className="card-image-d">
                    <img
                      alt="Event Image"
                      src={`${process.env.REACT_APP_BACKEND_API_URL}/image/${event?.file}`}
                      className="card-image"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div class="card-content">
                    <h3 class="card-title"> {event?.title}</h3>
                    <h6>
                      {formatDate(event?.startDate)}&nbsp; to &nbsp;
                      {formatDate(event?.endDate)}
                    </h6>
                  </div>
                  <div>
                    <button
                      onClick={() => moveToRegister(event._id)}
                      type="submit"
                      className="register-btn-event"
                    >
                      view details
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* Modal for larger image view */}
        <ToastContainer position={toast.POSITION.TOP_CENTER} />
      </div>
    </div>
  );
};

export default EventDetailsPage;
