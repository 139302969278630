import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { BASE_URL } from "./../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useHistory } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import  './App.css';
import { css } from "@emotion/core";

import { DropdownButton, Dropdown, ButtonGroup, Button } from "react-bootstrap";

function Memberfun(e) {
  // e.preventDefault();
  // const history = useHistory();

  const [id, setId] = useState(
    localStorage.getItem("id") != "null"
      ? Number(localStorage.getItem("id"))
      : false
  );
  console.log(id);

  const [count, setCount] = useState(0);
  const [values, setvalues] = useState(0);

  const [age, setAge] = useState();
  const [name, setName] = useState();
  const [weight, setWeight] = useState();
  const [height, setHeight] = useState();
  const [sex, setSex] = useState();
  const [profession, setProfession] = useState();
  const [dailyActivity, setDailyActivity] = useState();
  const [discease, setDiscease] = useState();
  const [physicalActivity, setphysical_activity] = useState();
  const [History, setHistory] = useState();
  const [bloodPressure, setBloodPressure] = useState();
  const [bloodPressure_other, setBloodPressureOther] = useState();
  const [chronicIllness, setChronicIllness] = useState();
  const [chronicIllness_other, setChronicIllnessOther] = useState();
  const [diffPhEx, setDiffPhEx] = useState();
  const [diffPhExOther, setDiffPhExOther] = useState();
  const [adviceFrPh, setAdviceFrPh] = useState();
  const [adviceFrPhOther, setAdviceFrPhOther] = useState();
  const [surgery, setSurgery] = useState();
  const [surgeryOther, setSurgeryOther] = useState();
  const [otherExerciseSport, setOtherExerciseSport] = useState();
  const [pregnancy, setPregnancy] = useState();
  const [pregnancyOther, setPregnancyOther] = useState();
  const [hyBreathLung, setHyBreathLung] = useState();
  const [hyBreathLungOther, setHyBreathLungOther] = useState();
  const [previousInjury, setPreviousInjury] = useState();
  const [previousInjuryOther, setPreviousInjuryOther] = useState();
  const [diabetes, setDiabetes] = useState();
  const [diabetesOther, setDiabetesOther] = useState();
  const [thyroid, settThyroid] = useState();
  const [thyroidOther, settThyroidOther] = useState();
  const [smokingHabit, settSmokingHabit] = useState();
  const [smokingHabitOther, settSmokingHabitOther] = useState();
  const [obesity, setObesity] = useState();
  const [obesityOther, setObesityOther] = useState();
  const [heartProblem, setHeartProblem] = useState();
  const [heartProblemOther, setHeartProblemOther] = useState();
  const [bloodCholesterol, setBloodCholestrol] = useState();
  const [bloodCholesterolOther, setBloodCholestrolOther] = useState();
  const [hernia, setHernia] = useState();
  const [herniaOther, setHerniaOther] = useState();
  const [athlete, setAthlete] = useState();
  const [athleteOther, setAthleteOther] = useState();
  const [badExperience, setBadExperience] = useState();
  const [badExperienceOther, setBadExperienceOther] = useState();
  const [negativeFeelings, setNegativeFeelings] = useState();
  const [negativeFeelingsOther, setNegativeFeelingsOther] = useState();
  const [unableToStick, setUnableToStick] = useState();
  const [unableToStickOther, setUnableToStickOther] = useState();
  const [devoteExercise, setDevoteExercise] = useState();
  const [involvedEnExercise, setInvolvedInExercise] = useState();
  const [involvedEnExerciseOther, setInvolvedInExerciseOther] = useState();
  const [currentExerciseRate, setCurrentExerciseRate] = useState();
  const [timespendExercise, setTimespendExercise] = useState();
  const [exerciseInterestOther, setExerciseInterestOther] = useState();
  const [exerciseDuringWork, setExerciseDuringWork] = useState();
  const [exerciseInterface, setExerciseInterface] = useState();
  const [exerciseProgramBenefit, setExerciseProgramBenefit] = useState();
  const [bestExercise, setBestExercise] = useState();
  const [accessToGym, setAccessToGym] = useState();
  const [accessToGymOther, setAccessToGymOther] = useState();
  const [equipments, setEquipments] = useState();
  const [equipments_Other, setEquipmentsOther] = useState();
  const [rankGoals, setRankGoals] = useState();
  const [discountCoupon, setDiscountCoupons] = useState();
  const [Cardivascular, setCardivascular] = useState();
  const [bodyFat, setBodyFat] = useState();
  const [bodySport, setBodySport] = useState();
  const [improveMoods, setImproveMoods] = useState();
  const [improveFlexibility, setImproveFlexibility] = useState();
  const [increaseEnergy, setIncreaseEnergy] = useState();
  const [feelBetter, setFFeelBetter] = useState();
  const [enjoyment, setEnjoyment] = useState();
  const [socialInteraction, setSocialInteraction] = useState();
  const [increaseStrength, setIncreaseStrength] = useState();
  const [rateGoalOther, setRateGoalOther] = useState();

  const handleChange = (event) => {
    setSex(event.target.value);
  };
  const submit = (e) => {
    e.preventDefault();
    let url = BASE_URL;

    const query = `UPDATE users SET
        age= '${age}',
        height='${height}',
        weight='${weight}',
        gender='${sex}', 
        profession='${profession}',
        dailyActivity='${dailyActivity}',
        discease='${discease}',
        elevatedBolldPressure ='${bloodPressure}',
        chronicIllness='${chronicIllness}',
        difficultWithPhysicalExcercise='${diffPhEx}',
        adviceFromPhysician='${adviceFrPh}',
        recentSurgery='${surgery}',
        pregnancy='${pregnancy}',
        lungProblemHistory='${hyBreathLung}',
        previousInjury='${previousInjury}',
        dabetesMetabolicSyndrome='${diabetes}',
        thyriodCondition='${thyroid}',
        cigaretteSmokingHabit='${smokingHabit}',
        obesity='${obesity}',
        elevatedBloodCholestrol='${bloodCholesterol}',
        hearyProblemHistory='${hyBreathLung}',
        hernia='${hernia}',
        excerciseLevel='${exerciseInterface}',
        schoolCollegeAthlete='${athlete}',
        physicalAtivityPrograms='${physicalActivity}',
        experienceWithFitnessTestingAndEvaluation='${negativeFeelings}',
        characterizeYourPresentAthleticAbility='${unableToStick}',
        whenYouExerciseHowImportantIsCompetition='${devoteExercise}',
        characterizeYourPresentMuscularCapacity='${involvedEnExercise}',
        characterizeYourPresentFlexibilityCapacity='${currentExerciseRate}',
        findYourselfUnableToStickWithExcercise='${timespendExercise}',
        timeYouWillingToDevoteToAnExercise='${otherExerciseSport}',
        involvedInRegularexercise='${athlete}',
        difficultyLevelOfYourCurrentExercise='${athlete}',
        timeSpentDoingThisExerciseProgram='${profession}',
        activitiesHaveYouParticipatedIn='${profession}',
        whatTypesOfExerciseInterestYou='${exerciseInterestOther}',
        availableDaysInTheWeek='${profession}',
        exerciseDuringWorkDay='${exerciseDuringWork}',
        exerciseProgramInterfaceWithYourJob='${exerciseInterface}',
        exerciseProgramBenefitYourJob='${exerciseProgramBenefit}',
        bestForYouToExercise='${bestExercise}',
        accessToGym ='${accessToGym}',
        equipments='${equipments}',
        goalsInUndertakingExercise='${rankGoals}',
        goal_improveCardivascularFitness='${Cardivascular}',
        goal_LossWeightBodyFat='${bodyFat}',
        goal_reshapeOrToneMyBodySport='${bodySport}',
        goal_improveMoodsAndAbilityToCopeWithStress='${improveMoods}',
        goal_improveFlexibility='${improveFlexibility}',
        goal_increaseEnergyLevel='${increaseEnergy}',
        goal_feelBetter='${feelBetter}',
        goal_enjoyment='${enjoyment}',
        goal_socialInteraction='${socialInteraction}',
        goal_increaseStrength='${increaseStrength}',
        goal_Other='${rateGoalOther}',
        discountCoupon='${discountCoupon}',
        isMember = 1
        
        
        WHERE id=${id}`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    console.log(query);
    axios
      .post(url, data)
      .then((res) => {
        alert("submitted successfully");
        // history.push("/")
        // setTimeout(() => {
        //     this.refreshParties();
        // }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const logout = () => {
  //     console.log("hii")
  //     history.push("/login");
  //     toast("Logout succesufull.");
  //     window.localStorage.clear();
  //     console.clear();
  // };

  // // let v;
  // let n;
  // const abc = (e) => {
  //     // e.preventDefault();
  //     n = e.target.name;
  //     // debugger;
  //     // v=e.target.__reactEventHandlers$bqxlxfcgn34.value;
  //     var v = e.target.value;
  //     console.log(v);
  //     setvalues({ ...values, [n]: v })

  // }

  const setcheck = (e) => {
    // e.preventDefault();
    let name = e.target.name;
    const { checked } = e.target;
    console.log(checked);
    setvalues({ ...values, [name]: checked });
    // debugger;
  };
  return (
    <div
      css={styles}
      style={{
        width: "100%",
        height: "100%",
        padding: "20px 0px",
        flex: "wrap",
        justifyContent: "center",
      }}
    >
      <div
        className="card"
        style={{
          padding: "15px",
          margin: "2em 10em 2em 10em",
          boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%)",
        }}
      >
        <div className="row">
          <div className="col-8">
            <h1 style={{ fontFamily: "Robot Thin", float: "left" }}>
              Fill Up This Form
            </h1>
          </div>
          <div className="col-4">
            <Link to="/">
              <button
                className="btn btn-danger"
                variant="outline-secondary"
                size="md"
                style={{ float: "right", marginRight: "1em", height: "40px" }}
              >
                <i class="fa fa-window-close"></i>
              </button>
            </Link>
          </div>
        </div>
        {count === 0 && (
          <div>
            <form>
              <div className="row">
                <div className="col-4">
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    onChange={(e) => setName(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-4">
                  <TextField
                    id="outlined-basic"
                    label="Age"
                    variant="outlined"
                    onChange={(e) => setAge(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-4">
                  <TextField
                    id="outlined-basic"
                    label="Height"
                    variant="outlined"
                    onChange={(e) => setHeight(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <TextField
                    id="outlined-basic"
                    label="Weight"
                    variant="outlined"
                    onChange={(e) => setWeight(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-4">
                  <TextField
                    id="outlined-basic"
                    label="Profession"
                    variant="outlined"
                    onChange={(e) => setProfession(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Sex</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setSex(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Male</MenuItem>
                      <MenuItem value={2}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <TextField
                    id="outlined-basic"
                    label="Daily Activity"
                    variant="outlined"
                    onChange={(e) => setDailyActivity(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-4">
                  <TextField
                    id="outlined-basic"
                    label="Discease"
                    variant="outlined"
                    onChange={(e) => setDiscease(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-4"></div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setCount(1);
                  }}
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    color: "white",
                    fontFamily: "Robot Thin",
                    fontSize: "25px",
                    width: "15%",
                    marginLeft: "1em",
                  }}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        )}

        {count === 1 && (
          <div>
            <form>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    {" "}
                    Describe any physical activity you do somewhat regulary.
                  </p>
                  <TextField
                    id="outlined-basic1"
                    label="Discease"
                    variant="outlined"
                    onChange={(e) => setphysical_activity(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <p>History of heart problems,chest pain,or stroke</p>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setSex(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    {" "}
                    Elevated blood pressure(specify your average blood pressure
                    measurement in the other section if possible)
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setBloodPressure(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Blood pressure Other"
                    variant="outlined"
                    onChange={(e) => setBloodPressureOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <p>
                      Any chronic illness or condition (please specify in other
                      section)
                    </p>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setChronicIllness(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Chronic Illness Other"
                    variant="outlined"
                    onChange={(e) => setChronicIllnessOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    {" "}
                    Difficult with physical exercise
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setDiffPhEx(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Blood pressure Other"
                    variant="outlined"
                    onChange={(e) => setDiffPhExOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <p>Advice from physician not to exercise</p>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setAdviceFrPh(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="advice_fr_ph_other"
                    variant="outlined"
                    onChange={(e) => setAdviceFrPhOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    {" "}
                    Recent surgery(last 12 months)
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setSurgery(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Surgery Other"
                    variant="outlined"
                    onChange={(e) => setSurgeryOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <p>Pregnancy(now or within 3 months)</p>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setPregnancy(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Pregnancy Other"
                    variant="outlined"
                    onChange={(e) => setPregnancyOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    {" "}
                    History of breathing or lung problems
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setHyBreathLung(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="History breath lung Other"
                    variant="outlined"
                    onChange={(e) => setHyBreathLungOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <p>
                      Muscle,joint, or back disorder, or any previous injury
                      still affecting you
                    </p>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setPreviousInjury(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Prevoius injury Other"
                    variant="outlined"
                    onChange={(e) => setPreviousInjuryOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Diabetes or metabolic syndrome
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setDiabetes(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Diabetes Other"
                    variant="outlined"
                    onChange={(e) => setDiabetesOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <p>Thyriod condition</p>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => settThyroid(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Thyroid Other"
                    variant="outlined"
                    onChange={(e) => settThyroidOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    {" "}
                    Cigarette smoking habit
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => settSmokingHabit(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Smoking Habit Other"
                    variant="outlined"
                    onChange={(e) => settSmokingHabit(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <p>
                      Obesity [body mass index (BMI) = 30kg/m<sup>2</sup> or Fat
                      % = 25% for men and 33% for women]
                    </p>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setObesity(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Obesity Other"
                    variant="outlined"
                    onChange={(e) => setObesityOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Elevated blood cholesterol [Please specify LDL and HDL
                    values if know]
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setBloodCholestrol(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Cholestrol Other"
                    variant="outlined"
                    onChange={(e) => setBloodCholestrolOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <p>History of heart problem in immediate family</p>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setHeartProblem(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Heart Problem Other"
                    variant="outlined"
                    onChange={(e) => setHeartProblemOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Hernia, or any condition that may be aggravated by lifting
                    weights or other physical activity
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setHernia(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Hernia Other"
                    variant="outlined"
                    onChange={(e) => setHernia(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <p>Were you a high school and/or college athlete</p>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setAthlete(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Athlete Other"
                    variant="outlined"
                    onChange={(e) => setAthleteOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Do you have any negative feelings towards, or have you had
                    any bad experience with, physical with, physical-activity
                    programs?
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setBadExperience(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Bad Experience Other"
                    variant="outlined"
                    onChange={(e) => setBadExperienceOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <FormControl fullWidth>
                    <p>
                      Do you start exercise programs but then find yourself
                      unable to stick with them?
                    </p>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setUnableToStick(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>Maybe</MenuItem>
                      <MenuItem value={4}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Unable to Stick Other"
                    variant="outlined"
                    onChange={(e) => setUnableToStickOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Are you currently involved in regular exercise?
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setInvolvedInExercise(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                      <MenuItem value={3}>
                        If yes then specify the type of exercise,min/day, and
                        days/week in other section
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    label="Involved in exercise Other"
                    variant="outlined"
                    onChange={(e) => setInvolvedInExerciseOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <p>
                    How much time you willing to devote to an exercise
                    program?-Mintes/day and days/week
                  </p>
                  <TextField
                    id="outlined-basic"
                    label="Devote Exercise"
                    variant="outlined"
                    onChange={(e) => setDevoteExercise(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <button
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#34495e",
                  color: "white",
                  fontFamily: "Robot Thin",
                  fontSize: "25px",
                  textDecoration: "none",
                }}
                onClick={() => {
                  setCount(0);
                }}
              >
                Back
              </button>

              <button
                className="btn btn-primary"
                onClick={() => {
                  setCount(2);
                }}
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  color: "white",
                  fontFamily: "Robot Thin",
                  fontSize: "25px",
                  width: "15%",
                  marginLeft: "1em",
                }}
              >
                Next
              </button>
            </form>
          </div>
        )}

        {count === 2 && (
          <div>
            <form>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Rate the difficulty level of your current exercise (1 being
                    light and 5 being hard)
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setCurrentExerciseRate(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-6">
                  <p>
                    How much time have you spent doing this exercise program?
                    (months or years)
                  </p>
                  <TextField
                    id="outlined-basic"
                    label="Time Spend"
                    variant="outlined"
                    onChange={(e) => setTimespendExercise(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>
                    What other exercise, sport, or recreational activities have
                    you participated in?
                  </p>
                  <TextField
                    id="outlined-basic"
                    label="Time Spend"
                    variant="outlined"
                    onChange={(e) => setOtherExerciseSport(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Can you exercise during work day?
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setExerciseDuringWork(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>
                    Rate yourself on a scale of 1 to 5 (1 indicating the lowest
                    value and 5 the highest)
                  </p>
                  <div className="row">
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Characterize your present athletic ability
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="present_athletic"
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="2">4</option>
                        <option value="3">5</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        when you exercise, how important is competition?
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="present_athletic"
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="2">4</option>
                        <option value="3">5</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Characterize your present muscular capacity
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="present_athletic"
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="2">4</option>
                        <option value="3">5</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Characterize your present flexibility capacity
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="present_athletic"
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="2">4</option>
                        <option value="3">5</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    What types of exercise interest you?
                  </p>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Walking"
                    onClick={setcheck}
                    value="walking"
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="exercise_interest_Walking"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    {" "}
                    Walking
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Jogging"
                    onClick={setcheck}
                    value="Jogging"
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="exercise_interest_Jogging"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    {" "}
                    Jogging
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Strength_training"
                    value="Strength training"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="strength training"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    {" "}
                    Strength training
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Clying"
                    value="Cycling"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="cycling"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    {" "}
                    Clying
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Traditional_aerobics"
                    value="Traditional arobics"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for=" traditional arobics"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    {" "}
                    Traditional arobics
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Racquet_sports"
                    value="Racquet sports"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for=" racquet sports"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    Racquet sports
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Stationary_biking"
                    value="Stationary biking"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="stationary biking"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    Stationary biking
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Elliptical_striding"
                    value="Elliptical striding"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="elliptical striding"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    Elliptical striding
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Yoga_pilates"
                    value="Yoga/pilates"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="yoga/pilates"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    Yoga/pilates
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Stair_climbing"
                    value="Stair climbing"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="stair climbing"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    Stair climbing
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Swimming"
                    value="Swimming"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="swimming"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    Swimming
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Other_activities"
                    value="Other activities"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="other activities"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    Other activities
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="exercise_interest_Other"
                    value="Other"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="other"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    Other
                  </label>
                  <br />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p>
                    Rate yourself on a scale of 1 to 5 (1 indicating the lowest
                    value and 5 the highest)
                  </p>
                  <span
                    style={{
                      cborderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    10-15
                  </span>
                  <select
                    name="age_10_15"
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="2">4</option>
                    <option value="3">5</option>
                  </select>
                  <br />
                  <span
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    16-20
                  </span>
                  <select
                    name="age_16_20"
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="2">4</option>
                    <option value="3">5</option>
                  </select>
                  <br />
                  <span
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    21-30
                  </span>
                  <select
                    name="age_21_30"
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="2">4</option>
                    <option value="3">5</option>
                  </select>
                  <br />
                  <span
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    31-40
                  </span>
                  <select
                    name="age_31_40"
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="2">4</option>
                    <option value="3">5</option>
                  </select>
                  <br />
                  <span
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    41-50
                  </span>
                  <select
                    name="age_41_50"
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="2">4</option>
                    <option value="3">5</option>
                  </select>
                  <br />
                  <span
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    50+
                  </span>
                  <select
                    name="age_50_Plus"
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="2">4</option>
                    <option value="3">5</option>
                  </select>
                  <br />
                </div>
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Please check the available days in the week
                  </p>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="available_days_Monday"
                    value="Monday"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  ></input>
                  <label
                    for="monday"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    {" "}
                    Monday
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="available_days_Tuesday"
                    value="Tuesday"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="tuesday"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    {" "}
                    Tuesday
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="available_days_Wednesday"
                    value="Wednesday"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="wednesday"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    {" "}
                    Wednesday
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="available_days_Thursday"
                    value="Thursday"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="thursday"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    Thursday
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="available_days_Friday"
                    value="Friday"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="friday"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    {" "}
                    Friday
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="available_days_Saturday"
                    value="Saturday"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for="saturday"
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    {" "}
                    Saturday{" "}
                  </label>
                  <br />

                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="available_days_Sunday"
                    value="Sunday"
                    onClick={setcheck}
                    style={{
                      borderRadius: "5px",
                      color: "black",
                      padding: "5px",
                      fontSize: "80%",
                    }}
                  />
                  <label
                    for=" sunday "
                    style={{ marginLeft: "4%", color: "black" }}
                  >
                    Sunday{" "}
                  </label>
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>Would an exercise program interface with your job?</p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setExerciseInterface(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Would an exercise program benefit your job?
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) =>
                        setExerciseProgramBenefit(e.target.value)
                      }
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>When is the best for you to exercise?</p>
                  <TextField
                    id="outlined-basic1"
                    label="Best Exercise"
                    variant="outlined"
                    onChange={(e) => setBestExercise(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Do you have access to gym?
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setAccessToGym(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={2}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic1"
                    label="Access to gym other"
                    variant="outlined"
                    onChange={(e) => setAccessToGymOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Do you have access to gym?
                  </p>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sex}
                      label="Sex"
                      onChange={(e) => setEquipments(e.target.value)}
                      style={{
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    >
                      <MenuItem value={1}>Trademills</MenuItem>
                      <MenuItem value={2}>Trademills Cycles</MenuItem>
                      <MenuItem value={3}>Weights</MenuItem>
                      <MenuItem value={4}>Weighted Dumbells</MenuItem>
                      <MenuItem value={5}>Kettlebell</MenuItem>
                      <MenuItem value={6}>Weightyes Machines</MenuItem>
                      <MenuItem value={7}>Resistance Bands</MenuItem>
                      <MenuItem value={8}>Weighted Bars</MenuItem>
                      <MenuItem value={9}>
                        Please add additional equipment's below
                      </MenuItem>
                      <MenuItem value={10}>Other</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="outlined-basic1"
                    label="Equipments other"
                    variant="outlined"
                    onChange={(e) => setEquipmentsOther(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <div className="col-6">
                  <p>
                    Rank your goals in undertaking exercise: What do yo want
                    exercise to do for you?
                  </p>
                  <TextField
                    id="outlined-basic1"
                    label="Rank goals"
                    variant="outlined"
                    onChange={(e) => setRankGoals(e.target.value)}
                    style={{
                      fontSize: "25px",
                      color: "black",
                      width: "300px",
                      marginBottom: "1em",
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                    Rate your goals (1-Not at all important), 3-Somewhat
                    important,5-Extremely important,
                  </p>
                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Improve cardivascular fitness
                      </span>
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <select
                        name="cardivascular"
                        onChange={(e) => setCardivascular(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Loss weight/body fat
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="body_fat"
                        onChange={(e) => setBodyFat(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Reshape or tone my body sport
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="body_sport"
                        onChange={(e) => setBodySport(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Improve moods and ability to cope with stress
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="improve_moods"
                        onChange={(e) => setImproveMoods(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      {" "}
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Improve flexibility
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="improve_flexibility"
                        onChange={(e) => setImproveFlexibility(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Increase energy level
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="increase_energy"
                        onChange={(e) => setIncreaseEnergy(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Feel better
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="feel_better"
                        onChange={(e) => setFFeelBetter(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Enjoyment
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="enjoyment"
                        onChange={(e) => setEnjoyment(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Social interaction
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="social_interaction"
                        onChange={(e) => setSocialInteraction(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="5">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Increase strength
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="increase_strength"
                        onChange={(e) => setIncreaseStrength(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: "5px" }}>
                    <div className="col-lg-10">
                      <span
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        Other
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <select
                        name="rate_goal_other"
                        onChange={(e) => setRateGoalOther(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                          fontSize: "80%",
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        {/* <option value="Other">Other</option> */}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <button
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#34495e",
                  color: "white",
                  fontFamily: "Robot Thin",
                  fontSize: "25px",
                  textDecoration: "none",
                }}
                onClick={() => {
                  setCount(1);
                }}
              >
                Back
              </button>

              <button
                className="btn btn-primary"
                onClick={() => {
                  setCount(3);
                }}
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  color: "white",
                  fontFamily: "Robot Thin",
                  fontSize: "25px",
                  width: "15%",
                  marginLeft: "1em",
                }}
              >
                Next
              </button>
            </form>
          </div>
        )}

        {count === 3 && (
          <div>
            <form>
              <div className="row">
                <div className="col-6">
                  <div
                    className="radio-container"
                    style={{
                      fontSize: "24px",
                      padding: "8px",
                      width: "auto",
                      margin: "auto",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                      {" "}
                      Payment Information
                    </p>
                    <div
                      style={{
                        fontSize: "23px",
                        color: "black",
                      }}
                    >
                      <p>
                        Please follow the below guidelines to pay for the
                        monthly or yearly subcription
                      </p>
                      <br />
                      <p>Payment plan for General Fitness:</p>
                      <ol>
                        <li>1 month - 500Rs</li>
                        <li>6 month - 2500Rs</li>
                        <li>12 month - 5000Rs</li>
                      </ol>

                      <p>Payment plan for Extra-care General Fitness:</p>
                      <ol>
                        <li>1 month - 750Rs</li>
                        <li>6 month - 3500Rs</li>
                        <li>12 month - 7000Rs</li>
                      </ol>
                      <p>
                        (Extra-care includes client with heart problems, 60+
                        age, joint, bones, muscle problems, any other diseases
                        that requires regular visits to physician)
                      </p>

                      <p>Payment plan for Sports Fitness:</p>
                      <ol>
                        <li>1 month - 1000Rs</li>
                        <li>6 month - 5000Rs</li>
                        <li>12 month - 10000Rs</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className="radio-container"
                    style={{
                      fontSize: "24px",
                      padding: "8px",
                      width: "auto",
                      margin: "auto",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                      {" "}
                      Before Payment
                    </p>
                    <div
                      style={{
                        fontSize: "23px",
                        color: "black",
                      }}
                    >
                      <p>
                        You may be thinking why I started this program with such
                        low rates for subscription. My general goal is to help
                        every individual to start exercising with proper
                        guidelines to decrease risks of injury and bad
                        experiences associated with exercise. Hence, I want
                        everyone to afford quality programs.
                      </p>
                      <p>
                        1. If you still think the rates are very high for you
                        then you can contact me through the email -{" "}
                        <a href="gouresh.powar@gmail.com">
                          (gouresh.powar@gmail.com).
                        </a>{" "}
                        I will provide a coupon that you can submit and get a
                        discount on the programs.
                      </p>
                      <p>
                        2. If you think the rates are very low then you can tip
                        me for my efforts. You can just simply add the tip to
                        the total payment. *Tips can be provided any time using
                        the payment methods listed below.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="row">
                  <div className="col-6">
                    <p style={{ fontFamily: "Robot Thin", color: "black" }}>
                      Submit any discount coupons/promo codes
                    </p>

                    <TextField
                      id="outlined-basic"
                      label="discount coupons Other"
                      variant="outlined"
                      onChange={(e) => setDiscountCoupons(e.target.value)}
                      style={{
                        fontSize: "25px",
                        color: "black",
                        width: "300px",
                        marginBottom: "1em",
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <FormControl fullWidth>
                      <p>Payment Methods (Phone number - +91 9146185352)</p>
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sex}
                        label="Sex"
                        onChange={(e) => setAdviceFrPh(e.target.value)}
                        style={{
                          width: "300px",
                          marginBottom: "1em",
                        }}
                      >
                        <MenuItem value={1}>Phone Pay</MenuItem>
                        <MenuItem value={2}>Google Pay</MenuItem>
                        <MenuItem value={3}>
                          UPI ID - sarvesh.powar007@okaxis
                        </MenuItem>
                        <MenuItem value={4}>
                          Bank Account #60066009523 (Bank of Maharashtra, IFSC -
                          MAHB0000326)
                        </MenuItem>
                        <MenuItem value={5}>Paypal - @goureshpowar1</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <button
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#34495e",
                  color: "white",
                  fontFamily: "Robot Thin",
                  fontSize: "25px",
                  textDecoration: "none",
                }}
                onClick={() => {
                  setCount(2);
                }}
              >
                Back
              </button>

              <button
                onClick={(e) => submit(e)}
                className="btn btn-primary"
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  color: "white",
                  fontFamily: "Robot Thin",
                  fontSize: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                Submit
              </button>
            </form>
          </div>
        )}
      </div>
      {/* {window.scrollTo(0, 0)} */}

      {/* Registration and Assessment From for Online Training Programs by Coach Gouresh Powar<br/>
            
                Fill out the following form as per the guidlines as completely
                as possible.The form includes following sections:<br/>
                1. Coach/Trainer information 
                2. Training Program information
                3. Liability Waiver
                4. Medical History
                5. Exercise history
                6. Scheduling
                7. Goals
                8. Payment information
                Please put aside 15-20 minutes to fill up the entire form. */}
    </div>
  );
}
const styles = css`
  .no-outline:hover {
    background-color: "grey";
  }
  input:hover {
    backgroud-color: grey;
  }
`;

export default Memberfun;
